(function () {
    var $body = $('body'),
        navClass = "mobileNav--show";

    $(function () {

        $('button.js_navigation__toggle').click(function () {
            if ($body.hasClass(navClass)) {
                $body.removeClass(navClass);
            }
            else {
                $body.addClass(navClass);
            }
        });
    });

    $(document).keyup(function (e) {
        if (e.keyCode === 27) {
            $('body').toggleClass('mobileNav--show');
        }
    });

})();