/// <reference path="helpers/common-helpers.js" />
/// <reference path="globals.js" />

(function () {

	var element = $("*[data-scrollto]");

	element.on('click',
		function(e) {
			e.preventDefault();
			var $this = $(this);
			var values = getValues($this);

			var elementExists = commonHelpers.checkElementExists(values.elem);

			if (!elementExists && application.debug) {
				console.warn('Element you wish to scroll to does not exist');
			} else {
				$('html, body').animate({
					scrollTop: values.elem.offset().top - values.offset
				}, 2000);
			}

		});

	function getValues($this) {
		var offset = 0;
		var val = $this.data('scrollto-offset');

		if (val !== undefined && !isNaN(val)) {
			offset = parseInt(val);
		}

		return objToReturn = {
			val: $this.data('scrollto'),
			elem: $("#" + $this.data('scrollto')),
			offset: offset
		};
	}
})();